export default {
  api: {
    applicants: {
      messages: '/api/applicants/messages',
      template_messages: '/api/applicants/template_messages',
      scouts: '/api/applicants/scouts',
    },
    clients: {
      declined_reasons: '/api/clients/declined_reasons',
      job_applicants: '/api/clients/job_applicants',
      messages: '/api/clients/messages',
      template_messages: '/api/clients/template_messages',
      selection_statuses: '/api/clients/selection_statuses',
    },
  },
};
